import "./NotFound.css";

const NotFound = () => {

  return (
    <div className="notfound" id="nfc">
      <h1 id="notfoundp">Page Not Found</h1>
    </div>
  );
};

export default NotFound;

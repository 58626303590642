import './OnlineResume.css'

const OnlineResume = () => {
    return ( 
        <div className='resumeCont'>
            <img src="/images/Resume1.jpg" alt="Resume" />
            <img src="/images/Resume2.jpg" alt="Resume" />
        </div>
    )
}


export default OnlineResume;
